<template>
    <div>
        <img class="assetsImg" src="../assets/image/header_zw.png" alt="">
        <div class="imgbox" v-if="imgShow">
            <p  style="text-align:center;font-size:14px;margin-bottom:12px;color: red;" ><strong>保存下面二维码，去微信添加债务老师<br>免费咨询处理方案</strong></p>
            <img class="imgUrl" :src="imgUrl" alt="">
        </div>
        <div class="imgbox1" v-if="!imgShow">
            <div class="item"> 
                <img src="../assets/image/debtServeName.png" alt="">
                <input type="text" v-model="personalData.username" maxlength="20" placeholder="输入您的真实姓名">
            </div>
            <div  class="item">
                <img src="../assets/image/debtServePhone.png" alt="">
                <input type="text" v-model="personalData.mobile"  maxlength="11" placeholder="输入您的手机号码">
            </div>
            <div class="GenerateAuthCode">
                <input type="number" v-model="personalData.code"  maxlength="6"  placeholder="输入您的验证码">
                <van-button :disabled="disabledBtnCode"   @click="getCode">{{ btnText }}</van-button>
            </div>
            <div class="agreement">
                <van-checkbox v-model="agreementChecked"   icon-size="16px" />
                <span style="color:666;margin-left:10px;">我已同意并阅读</span> <span style="color:#4772fc" @click="gotoprotocol">《个人信息授权》</span>
            </div>
            <div class="btnbox">
                <button @click="submit">立即获取解决方案</button>
                <p>超过10W人已经获取方案成功上岸</p>
            </div>
        </div>
        <img class="assetsImg" src="../assets/image/floot.png" alt="">
    </div>
</template>

<script>
import Vue from 'vue';
import {Checkbox, CheckboxGroup,Toast} from 'vant';

import {getCode,consult} from "../api/debServeApi"

Vue.use(Checkbox);
Vue.use(CheckboxGroup);
export default {
    data(){
        return {
            url:"",
            disabledBtnCode:false,
            agreementChecked:false,
            btnText:"获取验证码",
            imgShow:false,
            imgUrl:undefined,
            personalData:{
                username:"",
                mobile:undefined,
                code:undefined
            }

        }
    },
    created(){
      
    },
    methods:{
        gotoprotocol(){
            this.$router.push({
                path:"/queryProtocol"
            })
        },
        submit(){
            var namereg = /^[\u4E00-\u9FA5A-Za-z\s]+(·[\u4E00-\u9FA5A-Za-z]+)*$/;
            let phone = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/

            if(!namereg.test(this.personalData.username)){
                return Toast('请正确填写您的姓名');
            }
            if(!phone.test(this.personalData.mobile)){
                return Toast('请填写正确的手机号码');
            }
            if(!this.personalData.code){
                return Toast('请填写验证码');
            }
            if(!this.agreementChecked){
                return Toast('请同意《个人信息授权》协议');
            }
            consult(this.personalData).then(res=>{
                if(res.data.code == 200){
                    this.imgShow = true
                    this.imgUrl = res.data.data
                }else{
                    Toast(res.data.msg)
                }
            })
        },
        getCode(){
            let phone = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/
            if(!phone.test(this.personalData.mobile)){
                return Toast('请填写正确的手机号码');
            }
            let num = 59
            getCode({mobile:this.personalData.mobile}).then(res=>{
                if(res.data.code==200){
                    Toast("发送成功")
                    this.disabledBtnCode = true

                    let timer = setInterval(()=>{
                        if(num<=0){
                        clearInterval(timer)
                            this.btnText = "重新获取"
                            this.disabledBtnCode = false
                        }else{
                            this.btnText = num-- +"s重新获取"
                        }
                    },1000)
                }
            }).catch(err=>{
                this.disabledBtnCode = false
            })
        }
    },
}
</script>

<style lang="less" scoped>
    .assetsImg{
        width: 100%;
    }
    .imgbox{
        padding: 24px;
        text-align: center;
    }
    .imgUrl{
        width: 500px
    }
    .imgbox1{
        padding: 24px 48px;
        background: #fff;
        height: 600px;
        .item{
            padding: 0 24px;
            height: 80px;
            background: #F0F0F0;
            display: flex;
            align-items: center;
            border-radius:20px;
            margin-bottom: 30px;
            img{
                width: 36px;
                height: 36px;
            }
            input{
                height: 80%;
                width: 80%;
                background: none;
                border: none;
                outline: none;
                margin-left:20px;
                font-size: 26px;
            }
        }
        .GenerateAuthCode{
            height: 70px;
            // background: #F0F0F0;
            border-radius:30px;
            margin-bottom: 30px;
            display: flex;
            justify-content: space-between;
            input{
                border-radius:15px;
                width: 55%;
                height:78px;
                background: #F0F0F0;
                outline: none;
                border: none;
                font-size: 26px;
                text-indent: 30px;
            }
            button{
                width: 33%;
                height: 100%;
                border-radius:10px;
                border: none;
                background:#4087EF;
                color: #fff;
                font-size: 26px;
            }
        }
        .agreement{
            width: 92%;
            height: 50px;
            margin:  20px auto;
            /* background: skyblue; */
            display: flex;
            align-items: center;
            font-size: 24px;
        }
        .btnbox{
            button{
                width: 630px;
                height: 90px;
                background: #4087EF;
                box-shadow: 0px 8px 0px 0px rgba(64,135,239,0.67);
                border-radius: 45px;
                color: #fff;
                font-size: 30px;
                outline: none;
                border: none;
            }
            p{
                font-weight: bold;
                color: #FF4E00;
                text-align: center;
                line-height: 100px;
                font-size: 28px;
            }
        }
    }
    
</style>