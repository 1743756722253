/*
 * @Author: 申馨
 * @Date: 2023-08-22 09:32:23
 * @LastEditors: 申馨
 * @LastEditTime: 2024-01-19 14:08:15
 * @Description: file content
 * @FilePath: \hongan-h5\src\api\debServeApi.js
 */
import request from "@/utils/request";

// let url = "http://192.168.0.115:8081"//后台本地地址
let url = "https://www.yifenxinyong.com/prod-api"//正式地址
// 获取验证码
export const getCode = data=>{
    return request({
        method:"get",
        url:url+"/manage/consult/code",
        params:data
    })
}

// 提交个人信息数据
export const consult = data=>{
    return request({
        method:"post",
        url:url+"/manage/consult",
        data:data
    })
}


